import React from 'react';
import styled from 'styled-components';
import Slider from './Slider';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import PageContainer from '../../common/PageContainer';

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background-color: ${({ theme }) => theme.colors.white};
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 56px;
	padding: 64px 0;
	@media (min-width: 768px) {
		row-gap: 47px;
		padding: 47px 0;
	}
	@media (min-width: 1366px) {
		padding: 80px 0;
		grid-template-columns: 0.75fr 1fr;
		height: 100vh;
	}
	column-gap: 10%;
	color: ${({ theme }) => theme.colors.black};
	//overflow-x: hidden;
`;

const Side = styled.div`
	position: relative;
	color: ${({ theme }) => theme.colors.black};
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 100%;
	row-gap: 54px;
	min-height: 360px;
	overflow-x: hidden;
	&.reverse {
		order: -1;
		@media (min-width: 768px) {
			min-height: auto;
		}
		@media (min-width: 1366px) {
			order: unset;
		}
	}

	@media (min-width: 768px) {
		row-gap: 32px;
		&:not(.reverse) {
			min-height: 520px;
		}
	}
	@media (min-width: 1366px) {
		row-gap: 54px;
		aspect-ratio: unset;
	}
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 24px;
	line-height: 36px;
	font-family: ${({ theme }) => theme.fontFamily.primaryItalic};
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};
	color: ${({ theme }) => theme.colors.black};
	span.highlighted {
		color: ${({ theme }) => theme.colors.lightBrown};
	}
	@media (min-width: 1366px) {
		font-size: 32px;
		line-height: 50px;
	}
`;

const Desc = styled.div`
	font-size: 16px;
	line-height: 24px;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
`;

const NohoSlider = ({ data = {} }) => {
	return (
		<PageContainer className={'content-standard'}>
			<Wrapper id="scrollTo">
				<Side>
					<Slider data={data?.slides} />
				</Side>
				<Side className="reverse">
					<Header>
						<span>{data?.header || ''}</span>
						<span className="highlighted">{data?.highlightedHeader || ''}</span>
					</Header>
					<Desc dangerouslySetInnerHTML={getHtmlFromRedactorField(data?.description)} />
				</Side>
			</Wrapper>
		</PageContainer>
	);
};

export default NohoSlider;
