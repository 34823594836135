import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setScaleIntroAnimation } from '../state/scaleIntroAnim';
import { setIntroStartModule } from '../state/introStartModule';
import { setMenuColor } from '../state/menuColor';
import { setIntroLoadedOnce } from '../state/introLoadedOnce';
import styled from 'styled-components';
import Seo from '../components/seo/Seo';
import ScrollIndicator from '../components/ScrollIndicator/ScrollIndicator';
import PageContainer from '../common/PageContainer';
import { gsap } from 'gsap';
import { graphql } from 'gatsby';
import TextInTwoColumns from '../components/textInTwoColumns/TextInTwoColumns';
import { StaticImage } from 'gatsby-plugin-image';
import NohoSlider from '../components/noho/NohoSlider';
import Carousel from '../components/homePageCarousel/Carousel';
import TextSlider from '../components/textSlider/TextSlider';

const StyledView = styled.section`
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	height: 100vh;
	position: relative;
	text-align: center;
`;
const Banner = styled.section`
	background-color: ${({ theme }) => theme.colors.white};
	background-size: cover;
	background-position: center;
	display: flex;
	height: var(--inner-height);
	position: relative;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	div.gatsby-image-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		&:before {
			top: 0;
			left: 0;
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: ${({ theme }) => theme.colors.black};
			opacity: 0.5;
			z-index: 1;
		}
	}
`;

const BigHeader = styled.h1`
	position: relative;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 100px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 155px;
	opacity: 0;
	transform: translateY(50%);
	z-index: 1;
	@media (min-width: 768px) {
		font-size: 100px;
		line-height: 155px;
	}
	@media (min-width: 1366px) {
		font-size: 150px;
		line-height: 155px;
	}
`;

const SmallHeader = styled.h2`
	position: relative;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 44px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 54px;
	padding: 0 20px;
	opacity: 0;
	transform: translateY(50%);
	z-index: 1;
	@media (min-width: 768px) {
		font-size: 50px;
		padding: 0;
		line-height: 72px;
	}
	@media (min-width: 1366px) {
		font-size: 64px;
		line-height: 72px;
	}
`;

const StyledIndicator = styled.div`
	bottom: 0;
	left: 50%;
	position: absolute;
	transform: translate(-50%);
	z-index: 1;
	@media (max-width: 1024px) {
		height: 100px;
		width: 100px;
	}
`;
const NohoPage = ({ pageContext, data, ...props }) => {
	const { nohoData } = data;
	const pageData = nohoData?.nodes?.[0] || {};
	const seoData = pageData?.seoSettings || {};
	const BigHeaderText = pageData?.bigHeader || '';
	const SmallHeaderText = pageData?.smallHeader || '';
	const textInTwoColumnsHeader = pageData?.textInTwoColumnsHeader || '';
	const textInTwoColumnsDescription = pageData?.textInTwoColumnsDescription || '';
	const text = pageData?.text || '';
	const sliderData = {
		description: pageData?.sliderDescription || '',
		slides: pageData?.sliderSlides || [],
		header: pageData?.sliderHeader || '',
		highlightedHeader: pageData?.sliderHeaderHighlighted || '',
	};
	const carouselData = pageData?.carouselSlides || [];
	const dispatch = useDispatch();
	const global = pageContext?.globals?.[0] || [];
	const textIndicator = global?.textIndicator || '';
	const seoGlobal = pageContext?.seoGlobal?.data?.datoCmsSite?.globalSeo || [];
	const BigHeaderRef = useRef(null);
	const SmallHeaderRef = useRef(null);
	useEffect(() => {
		dispatch(setScaleIntroAnimation(true));
		dispatch(setIntroStartModule(false));
		dispatch(setIntroLoadedOnce(true));
		if (BigHeaderRef.current) {
			gsap.to(BigHeaderRef.current, {
				duration: 0.85,
				y: 0,
				opacity: 1,
				delay: 0.4,
				ease: 'power3.out',
			});
		}
		if (SmallHeaderRef.current) {
			gsap.to(SmallHeaderRef.current, {
				duration: 0.85,
				y: 0,
				opacity: 1,
				delay: 0.6,
				ease: 'power3.out',
			});
		}
	}, []);
	return (
		<>
			<Seo seoGlobal={seoGlobal} seoData={seoData} />
			<Banner>
				<StaticImage src={`../assets/image/homePage/first.webp`} alt="Noho" />
				<BigHeader ref={BigHeaderRef}>{BigHeaderText}</BigHeader>
				<SmallHeader ref={SmallHeaderRef}>{SmallHeaderText}</SmallHeader>
				<StyledIndicator id="indicatorBtn">
					<ScrollIndicator text={textIndicator} color pageContext={pageContext} />
				</StyledIndicator>
			</Banner>
			<NohoSlider data={sliderData} />
			<TextSlider text={text} />
			<Carousel data={carouselData} />
			<TextInTwoColumns header={textInTwoColumnsHeader} description={textInTwoColumnsDescription} />
		</>
	);
};

export default NohoPage;

export const query = graphql`
	query ($locale: String) {
		nohoData: allDatoCmsNohoPage(filter: { locale: { eq: $locale } }) {
			nodes {
				seoSettings {
					title
					description
					twitterCard
					image {
						url
					}
				}
				bigHeader
				smallHeader
				text
				textInTwoColumnsHeader
				textInTwoColumnsDescription
				sliderDescription
				carouselSlides {
					info
				}
				sliderSlides {
					info
				}
				sliderHeader
				sliderHeaderHighlighted
			}
		}
	}
`;
