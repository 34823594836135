import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import SlickSlider from 'react-slick';

const Wrapper = styled.div`
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	div {
		height: 100%;
	}
	.slick-initialized {
		div.slick-track {
			height: calc(100% - 35px);
		}
	}
	.slick-dots {
		bottom: 0;
	}
`;
const SliderInfo = styled.span`
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	animation: fadeIn 0.35s ease-in-out;
	display: flex !important;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	font-family: ${({ theme }) => theme.fontFamily.secondary};

	ul {
		li {
			margin: 0;
			button {
				&:before {
					color: ${({ theme }) => theme.colors.lightBrown};
					font-size: 10px;
				}
			}
		}
	}
`;

const settings = {
	dots: true,
	arrows: false,
	infinite: true,
	slidesToShow: 1,
	cssEase: 'ease-in-out',
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 3000,
};

const appendDots = (dots, data) => {
	const index = dots.find((dot) => dot.props.className.includes('slick-active')).key;
	const info = data[index]?.info || '';
	return (
		<SliderInfo key={index}>
			<p>{info}</p>
			<ul style={{ margin: '0px' }}> {dots} </ul>
		</SliderInfo>
	);
};
const Slider = ({ data }) => {
	return (
		<Wrapper>
			<SlickSlider {...settings} appendDots={(dots) => appendDots(dots, data)}>
				<StaticImage src={`../../assets/image/noho/nadgornikow.jpg`} layout="fullWidth" alt={'zdjecie w galerii 3'} />
				<StaticImage src={`../../assets/image/investment/dolnych_zewnatrz.webp`} layout="fullWidth" alt={'Dolnych Mlynow 10 '} />
				<StaticImage src={`../../assets/image/noho/mogilska.jpg`} layout="fullWidth" alt={'Mogilska'} />
				<StaticImage src={`../../assets/image/noho/lindego2.jpg`} layout="fullWidth" alt={'Lindego 2'} />
			</SlickSlider>
		</Wrapper>
	);
};

export default Slider;
