import React from 'react';
import styled from 'styled-components';
import PageContainer from '../../common/PageContainer';
import GetHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	min-height: 100vh;
	height: 100%;
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: ${({ theme }) => theme.colors.black};
`;

const StyledColumnsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	row-gap: 32px;
	padding-top: 64px;
	padding-bottom: 30px;
	@media (min-width: 768px) {
		padding-top: 0;
		row-gap: 48px;
	}
	@media (min-width: 1366px) {
		column-gap: 130px;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
	}
`;

const StyledHeader = styled.div`
	position: relative;
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 36px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 45px;
	@media (min-width: 768px) {
		font-size: 64px;
		line-height: 72px;
	}
`;

const StyledDescription = styled.div`
	position: relative;
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 16px;
	line-height: 26px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	padding: 0 10px;
	@media (min-width: 768px) {
	}
	@media (min-width: 1366px) {
		font-size: 16px;
		line-height: 26px;
		max-width: 536px;
	}
	li {
		list-style-type: none;
		position: relative;
		margin-bottom: 25px;
		&:before {
			content: '';
			position: absolute;
			left: -12px;
			top: 12px;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background-color: ${({ theme }) => theme.colors.lightBrown};
		}
	}
`;

const TextInTwoColumns = ({ header, description }) => {
	return (
		<Wrapper className="last">
			<PageContainer className="content-standard">
				<StyledColumnsWrapper>
					<StyledHeader dangerouslySetInnerHTML={GetHtmlFromRedactorField(header)} />
					<StyledDescription dangerouslySetInnerHTML={GetHtmlFromRedactorField(description)} />
				</StyledColumnsWrapper>
			</PageContainer>
		</Wrapper>
	);
};

export default TextInTwoColumns;
