import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const StyledWrapper = styled.div`
	color: ${({ theme }) => theme.colors.black};
	background-color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-weight: ${({ theme }) => theme.fontWeight.light};
	.marquee {
		position: relative;
		overflow: hidden;
		--offset: 20vw;
		--move-initial: calc(-25% + var(--offset));
		--move-final: calc(-50% + var(--offset));
		padding: 0 0 50px;
		@media (min-width: 768px) {
			padding: 50px 0 120px;
		}
		@media (min-width: 1366px) {
			padding: 50px 0 200px;
		}
	}

	.marquee__inner {
		width: fit-content;
		display: flex;
		position: relative;
		transform: translate3d(var(--move-initial), 0, 0);
		animation: marquee 10s linear infinite;
		animation-play-state: running;
	}

	.marquee p {
		font-size: 44px;
		line-height: 54px;
		@media (min-width: 768px) {
			font-size: 100px;
			line-height: 120px;
		}
		color: ${({ theme }) => theme.colors.black};
		font-family: ${({ theme }) => theme.fontFamily.primary};
		font-weight: ${({ theme }) => theme.fontWeight.light};
		width: max-content;
		span {
			margin-right: 30px;
			@media (min-width: 768px) {
				margin-right: 70px;
			}
		}
	}
	.marquee__text {
		.custom-redactor {
			display: flex;
			width: max-content;
			p {
				font-size: 44px;
				line-height: 54px;
				@media (min-width: 768px) {
					font-size: 100px;
					line-height: 120px;
				}
				color: ${({ theme }) => theme.colors.black};
				font-family: ${({ theme }) => theme.fontFamily.primary};
				font-weight: ${({ theme }) => theme.fontWeight.light};
				margin-right: 30px;
				@media (min-width: 768px) {
					margin-right: 70px;
				}
			}
		}
	}

	.marquee:hover .marquee__inner {
		//animation-play-state: paused;
	}

	@keyframes marquee {
		0% {
			transform: translate3d(var(--move-initial), 0, 0);
		}

		100% {
			transform: translate3d(var(--move-final), 0, 0);
		}
	}
`;

function TextSlider({ text }) {
	return (
		<StyledWrapper>
			<div className="marquee">
				<div className="marquee__inner" aria-hidden="true">
					<div className="marquee__text" dangerouslySetInnerHTML={getHtmlFromRedactorField(text)} />
					<div className="marquee__text" dangerouslySetInnerHTML={getHtmlFromRedactorField(text)} />
					<div className="marquee__text" dangerouslySetInnerHTML={getHtmlFromRedactorField(text)} />
					<div className="marquee__text" dangerouslySetInnerHTML={getHtmlFromRedactorField(text)} />
				</div>
			</div>
		</StyledWrapper>
	);
}

export default TextSlider;
